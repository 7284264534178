import { createModule } from 'redux-modux'

const initialState = {}

const setData = (state, action) => ({
  ...state,
  ...action.data
})

const updateFormData = (state, action) => ({
  ...state,
  ...action.data
})

const handlers = {
  setData,
  updateFormData
}

export default createModule({ moduleName: 'customer', initialState, handlers })