import toNumber from 'lodash/toNumber'
export default (() => {
  return {
    get: (cname) => {
      let name = cname + "="
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    set: ({ cname, cvalue, exdays= 1 }) => {
      const d = new Date();
      d.setTime(d.getTime() + (toNumber(exdays*24*60*60*1000)));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  }
})()