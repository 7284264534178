/* eslint-disable import/no-anonymous-default-export */
export default ({ list= [], demension= 1 }) => {
  let matrix = []
  let i = 0
  let k = 0

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % demension === 0) {
        k++;
        matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
}