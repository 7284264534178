/* eslint-disable no-extend-native */
import { createModule } from 'redux-modux'

const initialState = {
  uId: '',
  warehouse: '',
  historyState: ['/']
}

const setActiveComponent = (state, actions) => ({
  ...state,
  activeComponent: actions.activeComponent
})

const setUId = (state, actions) => ({
  ...state,
  uId: actions.data
})

const setWareHouse = (state, actions) => ({
  ...state,
  warehouse: actions.data
})

const updateHistoryState = (state, actions) => ({
  ...state,
  historyState: actions.data
})

const handlers = {
  setActiveComponent,
  setUId,
  setWareHouse,
  updateHistoryState
}

export default createModule({ moduleName: 'home', initialState, handlers })