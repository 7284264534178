import { Global, css } from '@emotion/core'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import React, { Suspense } from 'react'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'spc-general-component/build/style.css'
import 'react-spring-bottom-sheet/dist/style.css'
import MobileStyle from 'spc-general-component/lib/MobileStyle'
import { history } from './utils'
import configureStore from './store/configureStore'
import route from './routes'

const store = configureStore(window.__INITIAL_STATE__)

const globalStyles = css`
  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Black.eot');
    src: url('../fonts/Kanit-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Black.woff2') format('woff2'),
        url('../fonts/Kanit-Black.woff') format('woff'),
        url('../fonts/Kanit-Black.ttf') format('truetype'),
        url('../fonts/Kanit-Black.svg#Kanit-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-BoldItalic.eot');
    src: url('../fonts/Kanit-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-BoldItalic.woff2') format('woff2'),
        url('../fonts/Kanit-BoldItalic.woff') format('woff'),
        url('../fonts/Kanit-BoldItalic.ttf') format('truetype'),
        url('../fonts/Kanit-BoldItalic.svg#Kanit-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-BlackItalic.eot');
    src: url('../fonts/Kanit-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-BlackItalic.woff2') format('woff2'),
        url('../fonts/Kanit-BlackItalic.woff') format('woff'),
        url('../fonts/Kanit-BlackItalic.ttf') format('truetype'),
        url('../fonts/Kanit-BlackItalic.svg#Kanit-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Bold.eot');
    src: url('../fonts/Kanit-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Bold.woff2') format('woff2'),
        url('../fonts/Kanit-Bold.woff') format('woff'),
        url('../fonts/Kanit-Bold.ttf') format('truetype'),
        url('../fonts/Kanit-Bold.svg#Kanit-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-ExtraLight.eot');
    src: url('../fonts/Kanit-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-ExtraLight.woff2') format('woff2'),
        url('../fonts/Kanit-ExtraLight.woff') format('woff'),
        url('../fonts/Kanit-ExtraLight.ttf') format('truetype'),
        url('../fonts/Kanit-ExtraLight.svg#Kanit-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-ExtraBoldItalic.eot');
    src: url('../fonts/Kanit-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Kanit-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Kanit-ExtraBoldItalic.ttf') format('truetype'),
        url('../fonts/Kanit-ExtraBoldItalic.svg#Kanit-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-ExtraBold.eot');
    src: url('../fonts/Kanit-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-ExtraBold.woff2') format('woff2'),
        url('../fonts/Kanit-ExtraBold.woff') format('woff'),
        url('../fonts/Kanit-ExtraBold.ttf') format('truetype'),
        url('../fonts/Kanit-ExtraBold.svg#Kanit-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-ExtraLightItalic.eot');
    src: url('../fonts/Kanit-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Kanit-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Kanit-ExtraLightItalic.ttf') format('truetype'),
        url('../fonts/Kanit-ExtraLightItalic.svg#Kanit-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Light.eot');
    src: url('../fonts/Kanit-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Light.woff2') format('woff2'),
        url('../fonts/Kanit-Light.woff') format('woff'),
        url('../fonts/Kanit-Light.ttf') format('truetype'),
        url('../fonts/Kanit-Light.svg#Kanit-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Italic.eot');
    src: url('../fonts/Kanit-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Italic.woff2') format('woff2'),
        url('../fonts/Kanit-Italic.woff') format('woff'),
        url('../fonts/Kanit-Italic.ttf') format('truetype'),
        url('../fonts/Kanit-Italic.svg#Kanit-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-MediumItalic.eot');
    src: url('../fonts/Kanit-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-MediumItalic.woff2') format('woff2'),
        url('../fonts/Kanit-MediumItalic.woff') format('woff'),
        url('../fonts/Kanit-MediumItalic.ttf') format('truetype'),
        url('../fonts/Kanit-MediumItalic.svg#Kanit-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-LightItalic.eot');
    src: url('../fonts/Kanit-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-LightItalic.woff2') format('woff2'),
        url('../fonts/Kanit-LightItalic.woff') format('woff'),
        url('../fonts/Kanit-LightItalic.ttf') format('truetype'),
        url('../fonts/Kanit-LightItalic.svg#Kanit-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Medium.eot');
    src: url('../fonts/Kanit-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Medium.woff2') format('woff2'),
        url('../fonts/Kanit-Medium.woff') format('woff'),
        url('../fonts/Kanit-Medium.ttf') format('truetype'),
        url('../fonts/Kanit-Medium.svg#Kanit-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Regular.eot');
    src: url('../fonts/Kanit-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Regular.woff2') format('woff2'),
        url('../fonts/Kanit-Regular.woff') format('woff'),
        url('../fonts/Kanit-Regular.ttf') format('truetype'),
        url('../fonts/Kanit-Regular.svg#Kanit-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-SemiBold.eot');
    src: url('../fonts/Kanit-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-SemiBold.woff2') format('woff2'),
        url('../fonts/Kanit-SemiBold.woff') format('woff'),
        url('../fonts/Kanit-SemiBold.ttf') format('truetype'),
        url('../fonts/Kanit-SemiBold.svg#Kanit-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-SemiBoldItalic.eot');
    src: url('../fonts/Kanit-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Kanit-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Kanit-SemiBoldItalic.ttf') format('truetype'),
        url('../fonts/Kanit-SemiBoldItalic.svg#Kanit-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-ThinItalic.eot');
    src: url('../fonts/Kanit-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-ThinItalic.woff2') format('woff2'),
        url('../fonts/Kanit-ThinItalic.woff') format('woff'),
        url('../fonts/Kanit-ThinItalic.ttf') format('truetype'),
        url('../fonts/Kanit-ThinItalic.svg#Kanit-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Kanit';
    src: url('../fonts/Kanit-Thin.eot');
    src: url('../fonts/Kanit-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Kanit-Thin.woff2') format('woff2'),
        url('../fonts/Kanit-Thin.woff') format('woff'),
        url('../fonts/Kanit-Thin.ttf') format('truetype'),
        url('../fonts/Kanit-Thin.svg#Kanit-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  html,
  body {
    background: #fbfbfb;
    overflow: hidden;
    font-family: 'Kanit';
    font-size: 14px;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    iframe{
      body {
        .warp-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .box-loading{ z-index: 2000; }
  .clearfix:before,
  .clearfix:after {
      content: " ";
      display: table;
  }

  .clearfix:after {
      clear: both;
  }
  .clearfix {
      *zoom: 1;
  }

  .modal.is-active {
    z-index: 1009;
    .modal-card {
      width: 445px;
      margin: 0px auto;
    }
    @media only screen and (max-width: 384px) {
      .modal-card { width: 340px; }
    }
  }
  .box-datarange-wrapper { z-index: 2000; }
  
  .modal-card-foot {
    .row { width: 100%; }
  }

  .slide-pane__overlay{
    z-index: 1009;
    background-color: rgba(0,0,0,0.6) !important;
    top: 0px !important;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    .slide-pane__header {
      flex: 0 0 40px !important;
      border-bottom: none !important;
      .slide-pane__title {
        color: #4a4a4a;
        font-size: 24px;
        font-weight: 500;
        padding: 30px;
      }
      .slide-pane__subtitle { padding: 0px 30px 11px; }
    }
    .slide-pane__close {
      display: none !important;
    }
    .slide-pane__header {
      background: #FFFFFF !important;
    }
    .slide-pane__title-wrapper {
      margin-left: 10px;
      .slide-pane__title { color: #4a4a4a; }
    }
    .slide-pane__content {
      display: flex;
      flex-direction: column;
      padding: 0px 30px !important;
      width: 100%;
      height: calc(100% - 95px);
      background-color: #FFFFFF;
      overflow: auto;
    }

    .sliding-custom-promotion {
      .slide-pane__content {
        height: calc(100% - 140px);
      }
    }
  }
  .cart-blank {
    height: calc(100vh - 175px);
  }

  .app-max-width {
    max-width: 1024px;
    margin: 0px auto;
  }
  .stock-location {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 18px;
  }
  .react-toastify-wrapper,
  #box-react-alert-message{
    z-index: 2000;
  }

  .box-alert-message {
    font-size: 17px;
    text-align: center;
    padding: 22px 28px 18px 26px;
    min-width: 281px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px !important;
 }

 #box-react-alert-message{
    position: fixed;
    top: 0;
    left: 50%;
    right: 0px;
    display: inline-table;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-5%);
 }

 .ERROR .box-alert-message .close {
  color: #ed1c24;
  opacity: 1;
}

.SUCCESS .box-alert-message .close {
  color: #ed1c24;
  opacity: 1;
}

 .box-alert-message .close{
  color: #ffffff;
  display: block;
  background: white;
  width: 36px;
  text-align: right;
  padding: 0px 7px 0px 0px;
  box-shadow: rgba(0,0,0,0.4) 0px 1px 3px, rgba(0,0,0,0.3) 0px 0px 0px -1px, rgba(0,0,0,0.2) 0px -2px 0px inset;
  font-size: 21px;
  z-index: 999;
  right: -0.356%;
  top: -0.8057%;
  cursor: pointer;
  border-bottom-left-radius: 9px;
  border-top-right-radius: 4px;
    &:hover{ 
      color: rgba(210,210,210,1); 
    }
 }

  .react-select__menu {
    z-index: 2000;
  }

  /* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0,0,0, 0.05);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0, 0.2);
}

.announcement {
  .modal-card {
    display: block;
    position: relative;
    .close-icon {
      color: white;
      display: block;
      text-align: right;
      font-size: 22px;
      cursor: pointer;
      margin-right: 20px;
      margin-top: 25px;
      &:hover{ color: red;}
    }

    .modal-card-head { display: none !important;}
  }
}


.announcement2 {
  .modal-card-body{
    background-color: rgba(255,255,255,0);
    overflow: auto;
    width: 100%;
    padding-top: 30px;
    margin: auto auto;
    display: block;
    height: 100%;
    border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      animation: fadeInAnimation ease 0.3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
  }

  .close-icon {
    color: #ffffff;
    display: block;
    background: red;
    width: 35px;
    text-align: right;
    padding: 3px 6px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 3px, rgba(0, 0, 0, 0.3) 0px 0px 0px -1px, rgba(0, 0, 0, 0.2) 0px -2px 0px inset;
    font-size: 22px;
    z-index: 999;
    right: 20px;
    margin-top: 30px;
    cursor: pointer;
    border-top-right-radius: 4.5px;
    position: absolute;
    border-bottom-left-radius: 9px;
    &:hover{ 
      color: rgba(210,210,210,1); 
    }
  }
  

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}


  }
`

function Root() {
  return (
    <Provider store={store}>
      <MobileStyle />
      <Suspense fallback={null}>
        <Global styles={globalStyles} />
        <Router history={history}>{route}</Router>
      </Suspense>
    </Provider>
  )
}

export default Root
