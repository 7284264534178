import { Route, Switch } from 'react-router-dom'
import React, { lazy } from 'react'
import styled from '@emotion/styled'

const Home = lazy(() => import('./pages/Home'))
const Promotion = lazy(() => import('./pages/Promotion'))
const Condition = lazy(() => import('./pages/Condition'))
const Privacy = lazy(() => import('./pages/Privacy'))

const AppPlication = styled.div`
  display: block;
  width: 100%;
  margin: 0px auto;
  height: 100vh;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
`



export default (
  <AppPlication className='application'>
    <Switch>
      <Route path='/promotion/:brand' component={Promotion} />
      <Route path='/product/:product_code' component={Home} />
      <Route path='/category/:category' component={Home} />
      <Route path='/brand/:brand' component={Home} />
      <Route path='/condition' component={Condition} />
      <Route path='/privacy' component={Privacy} />
      <Route path='/' component={Home} />
      <Route path='/:activity' component={Home} />
      <Route path='/flashsale' component={Home} />
      <Route component={() => <div>404 Page not found</div>} />
    </Switch>
  </AppPlication>
)
