import { createModule } from 'redux-modux'

const initialState = {
}

const setData = (state, action) => ({
  ...state,
  ...action.data
})

const handlers = {
  setData
}

export default createModule({ moduleName: 'product', initialState, handlers })