import { combineReducers } from 'redux'
import home from './home'
import list from './list'
import form from './form'
import product from './product'
import customer from './customer'

export default combineReducers({
  home: home.state,
  list: list.state,
  form: form.state,
  product: product.state,
  customer: customer.state
})
