import { createModule } from 'redux-modux'

const initialState = {
  formProcess: false,
  formProcessResult: {
    isSuccess: true,
    message: ''
  }
}

const updateFormData = (state, action) => ({
  ...state,
  ...action.data
})

const updateFormProcess = (state, action) => ({
  ...state,
  formProcess: action.process
})

const updateFormProcessResult = (state, action) => ({
  ...state,
  formProcessResult: action.result
})

const handlers = {
  updateFormData,
  updateFormProcess,
  updateFormProcessResult
}

export default createModule({ moduleName: 'form', initialState, handlers })
