const kbank_decode_round= process.env.REACT_APP_KBANK_DECODE_ROUND || ''
export default  (_data, _jsonParse = true, _round = kbank_decode_round) => {
	
    if (_data === null || _data === undefined) {
        return null;
    }
    let newData = _data;
    for (let i = 0; i < _round; i++) {
        newData = decodeURIComponent(escape(atob(newData)));
    }
    return _jsonParse ? JSON.parse(newData) : newData;

}